import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import DetailLayout from '../../containers/layout/detail-layout'
import { LatestEvents } from './event-detail.style'

const EventDetail = ({ data, location, pageContext }) => {
  const latestEvents = data.latest.edges.map((edge) => edge.node)

  return (
    <DetailLayout
      location={location}
      pageContext={pageContext}
      pageData={data.page.edges[0].node}
    >
      {latestEvents.length > 0 && (
        <LatestEvents
          padding={true}
          className="container-xl"
          list={latestEvents}
          title="Ook interessant voor u"
        />
      )}
    </DetailLayout>
  )
}

export const query = graphql`
  query EventDetailQuery($id: String) {
    page: allContentfulEvenement(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          title
          date(locale: "nl-NL")
          shortDate: date(locale: "nl-NL", formatString: "DD MMMM YYYY")
          longDate: date(locale: "nl-NL", formatString: "dddd, DD MMMM, YYYY")
          startTime: date(locale: "nl-NL", formatString: "HH:mm")
          duration
          price
          geoLocation: location
          description {
            description
            childMarkdownRemark {
              html
            }
          }
          content {
            json
          }
          themes {
            id
            title
          }
          image {
            file {
              url
              fileName
              contentType
            }
            fluid(maxWidth: 900) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          contact {
            id
            type
            name
            title
            township
            phone
            mobile
            email
            image {
              fixed(
                width: 100
                height: 100
                cropFocus: FACE
                resizingBehavior: FILL
              ) {
                ...GatsbyContentfulFixed_withWebp
              }
            }
          }
          downloads {
            id
            title
            description
            file {
              url
              fileName
              contentType
            }
          }
        }
      }
    }

    latest: allContentfulEvenement(
      limit: 3
      sort: { fields: date, order: ASC }
      filter: { id: { ne: $id } }
    ) {
      edges {
        node {
          id
          slug
          title
          day: date(locale: "nl-Nl", formatString: "DD")
          month: date(locale: "nl-Nl", formatString: "MMM")
          themes {
            title
          }
        }
      }
    }
  }
`

EventDetail.propTypes = {
  headingStyle: PropTypes.object,
}

export default EventDetail
